import { ethers } from "ethers";
import { scramblesContractAddress } from "../contracts"
import { useContractCall, useContractFunction } from "@usedapp/core";
import { Contract } from "@ethersproject/contracts";

import scramblesContractAbi from "../abi/scramblesContract.json";

const scramblesContractInterface = new ethers.utils.Interface(scramblesContractAbi);
const redeemContract = new Contract(scramblesContractAddress, scramblesContractInterface);

export function useCount() {
    const [count]: any = useContractCall({
        abi: scramblesContractInterface,
        address: scramblesContractAddress,
        method: "totalSupply",
        args: [],
    }) ?? [];
    //console.log(count);
    return count;
}

export function useBalance(address: string) {
    const [bal]: any = useContractCall({
        abi: scramblesContractInterface,
        address: scramblesContractAddress,
        method: "balanceOf",
        args: [address],
    }) ?? [];
    return bal;
}

export function useContractMethod(methodName: string) {

    const { state, send } = useContractFunction(redeemContract, methodName, {});
    return { state, send };
}